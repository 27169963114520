import React, { Component } from 'react';
import Scrollspy from 'react-scrollspy';
import Scroll from './Scroll';


import avatar from '../assets/images/logo-borderless-sq.png';
// import avatar from '../assets/img/normal_cat.jpg';
import config from '../../config';

// import { StaticImage } from 'gatsby-plugin-image';
export class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [
        // { content: 'Home', href: 'about' },
        { content: 'Support Coordination', href: 'support-coordination' },
        { content: 'Going Above & Beyond', href: 'above-beyond' },
        { content: 'FAQ', href: 'faq' }, // json
        { content: 'Reviews', href: 'reviews' }, // json
        { content: 'Staff', href: 'staff' },

        { content: 'Infinity Tomorrow', href: 'tomorrow' }, // markdown

        { content: 'Contact Us', href: 'contact' }, // more json
        
      ],
      isCollapsed: true,
    };
    this.toggleNavbar = this.toggleNavbar.bind(this);
  }

  toggleNavbar() {
    this.setState({
      isCollapsed: !this.state.isCollapsed,
    });
  }

  //   ```toc
  // # This code block gets replaced with the TOC
  // exclude: Table of Contents
  // tight: false
  // ordered: false
  // from-heading: 2
  // to-heading: 6
  // class-name: "table-of-contents"
  // ```

  render() {
    const { tabs, isCollapsed } = this.state;
    return (
      <nav
        className="navbar navbar-expand-lg navbar-light bg-primary fixed-top"
        id="sideNav"
      >
        <div className="d-flex justify-content-between align-items-center w-100">
        <div>
          <span className="navbar-text mr-3">
            Call:<a href="tel:8556669696">855-666-9696</a>
          </span>
        </div>
        <div>
          <a className="navbar-brand" href="#page-top">
            <span className="d-block d-lg-none">
              {config.firstName} {config.lastName}
            </span>
            <span className="d-none d-lg-block">
              <img
                className="img-fluid img-profile rounded-circle mx-auto mb-2"
                src={avatar}
                alt=""
              />
            </span>
          </a>
        </div>
      </div>

            {/* 
            <StaticImage
              className="img-fluid img-profile rounded-circle mx-auto mb-2"
              src={avatar}
              alt=''
            /> */}
        
        <button
          className={`navbar-toggler navbar-toggler-right ${
            isCollapsed ? 'collapsed' : ''
          }`}
          type="button"
          data-toggle="collapse"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={this.toggleNavbar}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className={`collapse navbar-collapse ${isCollapsed ? '' : 'show'}`}
          id="navbarSupportedContent"
        >
          <Scrollspy
            items={tabs.map(s => s.href)}
            currentClassName="active"
            offset={-300}
            className="navbar-nav"
          >
            {tabs.map((tab, i) => {
              const { href, content } = tab;
              return (
                <li className="nav-item" key={href}>
                  <Scroll type="id" element={href}>
                    <a className="nav-link" href={`#${href}`}>
                      {content}
                    </a>
                  </Scroll>
                </li>
              );
            })}
          </Scrollspy>
        </div>
      </nav>
    );
  }
}

export default Sidebar;
