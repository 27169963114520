import React from 'react';
import Layout from '../components/Layout';

import {
  Accordion,
  Card,
  // Carousel,
  ListGroup,
  Row,
  Col,
  // CardColumns,
  // CardDeck,
  // CardGroup,
  Container,
  Button,
  ButtonGroup,
  // Modal,
  Jumbotron,
  Image,
  // Container
} from 'react-bootstrap';

import ReactImageFallback from 'react-image-fallback';

// import { Carousel } from 'react-responsive-carousel';
import Carousel from 'react-grid-carousel';

import Sidebar from '../components/Sidebar';

import jstaff from '../data/staff.json';

import jabovebeyond from '../data/abovebeyond.json';

import jinfinitytomorrowposts from '../data/infinitytomorrowposts.json';

import jreviews from '../data/reviews.json';
import jfaq from '../data/faq.json';

import config from '../../config';
import ContactForm from "../components/ContactForm";



const IndexPage = () => (
  <Layout>
    <Sidebar />
    <div className="p-0">
      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="page-top"
      >
        <div className="w-100">
          <h2 className="display-1">
            {config.firstName}
            <span className="text-primary">{config.lastName}</span>
          </h2>

          <h3 className="mb-2">
            <strong>{config.slogan}</strong>
          </h3>
          <h3 className="mb-4">
            <strong>
              {config.blurb}
              <br />
              <i>&ldquo;{config.blurbsubtitle}&rdquo;</i>
            </strong>
          </h3>

          <div className="">
            {/* <CardColumns> */}
            <Card>
              <Card.Body>
                <Carousel autoplay={true}>
                  {config.featuredPhotos.map((photos, i) => {
                    const { href, alt } = photos;
                    var url = `../img/${href}`;

                    return (
                      <Carousel.Item IndexPage={i}>
                        <Image fluid alt={alt} src={url} />
                      </Carousel.Item>
                    );
                  })}
                </Carousel>
              </Card.Body>
              <Card.Footer>
                <ButtonGroup className="d-flex ">
                  {config.featuredLinks.map(social => {
                    const { icon, url, name, phone } = social;
                    return (
                      <Button
                        variant="success"
                        size="lg"
                        className="w-100 flex-nowrap mx-auto mb-2"
                        key={url}
                        href={url}
                      >
                        <i className={`fab ${icon}`}></i>
                        <br />
                        {name}
                        <br />
                        {phone}
                      </Button>
                    );
                  })}
                </ButtonGroup>
              </Card.Footer>
            </Card>
          </div>
        </div>
      </section>

      <hr className="m-0" />

      <section className="new-support section-padding p-lg-3 d-flex align-items-center" id="new-section">
  <div className="w-100">
    <Card>
      <Card.Footer>
        <Button
          href="../forms/support-coordination-agency-selection.pdf"
          className="btn btn-outline btn-block"
        >
          {' '}
          Support Coordination Agency Selection Form{' '}
        </Button>
      </Card.Footer>
    </Card>
  </div>
</section>


      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="support-coordination"
      >
        <div className="w-100">
          <h2>Support Coordination</h2>
          <div className="d-flex w-100">
            {/* <CardColumns> */}
            <Card>
              <Card.Body>
                <Row className="align-items-center justify-content-center">
                  <Col lg={4} md={8} sm={6}>
                    <h4>
                      <strong className="">
                        Everyone registered with DDD will need to identify a
                        Support Coordinating agency. This is an important
                        decision because the Support Coordinator is the team
                        member who helps manage the services that each person
                        receives. The form below can be used to select or change
                        a Support Coordination Agency. Once completed, the form
                        can be physically mailed to DDD or emailed to them.
                      </strong>
                    </h4>
                    <h5 className="align-items-center">Map of our counties served:</h5>
                  </Col>
                  <Col lg={4} md={4} sm={6}>
                    <img
                      className="responsive mx-auto"
                      src="../img/New_Jersey_Counties_Outline _newmap.png"
                    />
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <Button
                  href="../forms/support-coordination-agency-selection.pdf"
                  className="btn btn-outline btn-block"
                >
                  {' '}
                  Support Coordination Agency Selection Form{' '}
                </Button>
              </Card.Footer>
            </Card>
          </div>
        </div>
      </section>

      <hr className="m-0" />

      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="above-beyond"
      >
        <div className="w-100">
          <h2 className="mb-0">Going Above &amp; Beyond</h2>

          <h4>
            <strong className="">
              There are several reasons why Infinity Today is the premier choice
              for Support Coordination.
            </strong>
          </h4>

          <p className="">Here are some of the details:</p>

          {/* <CardColumns> */}
          {jabovebeyond.map(jab => {
            const { title, description } = jab;
            return (
              <Card>
                <Card.Header>
                  <strong>{title}</strong>
                </Card.Header>
                <Card.Body>{description}</Card.Body>
              </Card>
            );
          })}
          {/* </CardColumns> */}
        </div>
      </section>

      <hr className="m-0" />

      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="faq"
      >
        <div className="w-100">
          <h2>Frequently Asked Questions</h2>
          <Accordion>
            {jfaq.map(qa => {
              const { Q, A, id } = qa;
              // var i += 1;
              return (
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey={id}>
                    <h4>{Q}</h4>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={id}>
                    <Card.Body>{A}</Card.Body>
                  </Accordion.Collapse>
                </Card>
              );
            })}
          </Accordion>
        </div>
      </section>
 
      <section className="year-section p-3 p-lg-5 d-flex align-items-center text-center my-4" id="turnover-years">
        <div className="w-100">
          <Image src="../img/5year.jpg" alt="5yr Celebration" fluid />
          <p className="mt-2" style={{fontWeight: 'bold', fontSize: '20Ppx'}}>
            Turnover is not a problem at Infinity Today. These staff members recently celebrated being with our agency for 5yrs and more!
          </p>
        </div>
      </section>

      

      <hr className="m-0" />

      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="reviews"
      >
        <div className="w-100">
          <h2>Reviews</h2>
          <p>
      At the end of each year, Infinity Today asks the people that we serve to share how they think we are doing. 
      While many of our reviews can be found below, you can reach out to us whenever needed. Please feel free to 
      call or email us if you want to discuss your needs and concerns. We are always available to speak to you when 
      service changes are needed. Of course, you are also welcome to reach out to tell us how much you love your 
      Support Coordinator too! No matter the reason you contact us, we welcome your feedback and take it very seriously! 
      <br/>
      Please email us with your thoughts at <a href="#contact">Contact us for information and to share your feedback </a>.
    </p>
          <Carousel
            cols={2}
            rows={2}
            loop={true}
            activeIndex={1}
            interval={2000}
            responsiveLayout={[
              {
                breakpoint: 1200,
                cols: 2,
                rows: 1,
              },
              {
                breakpoint: 990,
                cols: 1,
                rows: 1,
              },
            ]}
            // containerStyle={{
            //   height: 'auto'
            // }}
            pause={false}
          >
            {jreviews.map((rvw, i) => {
              const { Name, Review, Year } = rvw;

              return (
                <Carousel.Item key={i}>
                  <Card className="d-flex h-100">
                    <Card.Body>
                      {/* <Container> */}
                      {/* <blockquote> */}
                      <h3 className="d-flex w-100 justify-content-center align-items-center align-content-center h-100">
                        {Review}
                      </h3>
                      {/* </blockquote> */}
                      {/* </Container> */}
                    </Card.Body>
                    <Card.Footer>
                      <i>
                        {' '}
                        - {Name}, {Year}{' '}
                      </i>
                    </Card.Footer>
                  </Card>
                </Carousel.Item>
              );
            })}
          </Carousel>
        </div>
      </section>

      <hr className="m-0" />

      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="staff"
      >
        <div className="w-100">
          <h2 className="mb-0">Meet Our Amazing Staff!</h2>

          <Carousel
            className="d-flex"
            cols={2}
            rows={1}
            loop={true}
            activeIndex={1}
            interval={4000}
            containerStyle={{ margin: '0 auto' }}
            responsiveLayout={[
              {
                breakpoint: 1200,
                cols: 2,
                rows: 1,
              },
              {
                breakpoint: 990,
                cols: 2,
                rows: 1,
              },
            ]}
            // pause={'hover'}
          >
            {/* <CardGroup> */}

            {/* <CardGroup> */}
            {jstaff.map(member => {
              const {
                Title,
                FirstName,
                LastName,
                Job,
                Extension,
                Expertise,
                emailAddress,
                Bio,
              } = member;

              const ph = `${config.phone},${Extension}`;
              const url = `tel:${ph}`;

              const Name = `${FirstName} ${LastName}`;
              var titlename = '';

              // staff photo naming conventions
              const photoPath = `../img/staff/${FirstName}${LastName.replace(
                `'`,
                ''
              )}_s.jpg`.toLowerCase();
              console.log(photoPath);
              if (Title) {
                titlename += `${Name} , ${Title}`.trim();
              } else {
                titlename = Name;
              }

              return (
                <Carousel.Item>
                  <Card className="h-100">
                    <Container className="staffphoto">
                      <ReactImageFallback
                        src={photoPath}
                        className="stock"
                        fallbackImage="../img/staff/placeholder.png"
                        alt={`Photo of ${FirstName}`}
                      />
                    </Container>
                    <div className="">
                      <h3 className="caption">{titlename}</h3>
                      <h4 className="text-muted mb-1 text-center card-subtitle">
                        {Job} <br /> {Expertise}
                      </h4>
                    </div>
                    <Card.Footer>
                      <a class="btn btn-outline-info btn-block" href={url}>
                        {config.primaryPhone} x {Extension}
                        <br />
                        <i className={`fas fa-phone`}></i>
                      </a>

                      <a
                        class="btn btn-outline-info btn-block"
                        href={'mailto:' + emailAddress}
                      >
                        {emailAddress}
                        <br />
                        <i className={`fas fa-envelope`}></i>
                      </a>
                    </Card.Footer>
                    <Card.Body>{Bio}</Card.Body>
                  
                  </Card>
                </Carousel.Item>
              );
            })}
          </Carousel>
        </div>
      </section>

      <hr className="m-0" />

      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="tomorrow"
      >
        <div className="d-flex flex-column w-100">
          <Jumbotron>
            <div className="w-100">
              <h2 className="display-2">
                Infinity <span className="text-primary">Tomorrow</span>
              </h2>

              <h3 className="mb-2">
                <strong>
                  Our <span className="">Mission</span>
                </strong>
              </h3>

              {/* <h4 className="mb-2">
            <strong>Our <span className="">Mission</span></strong>
          </h4> */}

              <strong className="">
                {jinfinitytomorrowposts[0].description}
              </strong>
              {/* <hr className="m-0" /> */}
            </div>
          </Jumbotron>

          <Card>
            {/* <Card> */}
            {/* This is where the BoD are located */}
            <Card.Header>{jinfinitytomorrowposts[1].title}</Card.Header>
            <Card.Body>
              <ListGroup className="list-group-flush">
                {jinfinitytomorrowposts[1].bod.map(humans => {
                  const { Role, Name } = humans;
                  return (
                    <ListGroup.Item>
                      <strong>{Name}</strong> - {Role}
                    </ListGroup.Item>
                  );
                })}
              </ListGroup>
            </Card.Body>
            {/* <Card.Footer>{title}</Card.Footer> */}
          </Card>

          <Card>
            {/* <Card> */}
            {/* This is where the BoD are located */}
            <Card.Header>{jinfinitytomorrowposts[2].title}</Card.Header>
            <Card.Body>{jinfinitytomorrowposts[2].description}</Card.Body>

            <Card.Footer>
             {} <Button
                className="btn-block"
                href={jinfinitytomorrowposts[2].link}
              >
                {/* fab refers to FontAwesome the icon set */}
                <i className={`fab ${jinfinitytomorrowposts[2].icon}`}></i>
                <br />
                {jinfinitytomorrowposts[2].link_label}
              </Button>
              <strong>Donations in the form of checks can be mailed to:</strong>
    <br />
    Infinity Tomorrow
    <br />
    350 W Passaic St
    <br />
    Rochelle Park, NJ 07662
            </Card.Footer>
            {/* <Card.Footer>{title}</Card.Footer> */}
          </Card>
          {/* {jinfinitytomorrowposts.map(pst => {
                  const { title, description, link, link_label, icon, bod } = pst;

                  if (link) {
                    return (
                      <Card>
                        <Card.Header>{title}</Card.Header>
                        <Card.Body>
                          {description}

                        </Card.Body>
                        <Card.Footer>
                          <Button href={link}>
                            <i className={`fab ${icon}`}></i>
                            <br />
                            {link_label}
                          </Button>
                        </Card.Footer>
                      </Card>
                    );
                  } else {
                    return (
                      <Card>
                        <Card.Header>{title}</Card.Header>
                        <Card.Body>
                          {description}
                          <br />
                        </Card.Body>
                      </Card>
                      );
                  }
                })} */}

          <Card>
            <Card.Header>
              Thoughts from our vice president Heather Fuentes
            </Card.Header>
            <Card.Body>
              <blockquote>
                &ldquo;As parents of a child with special needs, my husband and
                I always have to think ahead. Whether preparing for each school
                day, a weekend museum trip, or summer plans, preparation is key
                to even middling success. We try to consider the range of
                possible outcomes and pair them with contingency plans, from
                noise-sensitivity headphones to favorite books, activity pages,
                snacks, fresh clothes and more. Our son has autism, ADHD and
                anxiety. But these diagnoses aren’t the sum of him. He is also
                kind, creative and curious. He has the capacity to be nearly
                anything his heart desires, but to achieve his best, he also
                requires significant support and adaptations to manage school
                and meet his educational and occupational goals. As parents, we
                have twin broad goals always in mind, one for us, and one for
                him. Our parental aim is to advocate fiercely for our son, and
                to provide his support structure (which we cannot do alone). For
                our son, our goal is to keep him on the path to his most
                successful self, whoever he may develop into. Our son is only 9
                right now, and his path has never been a straight line. Every
                service delay or failure, every misaligned therapeutic or
                educational setting, has come with a corollary cost, ranging
                from minor glitch to devastating setback. To administrators he
                may be a name on paper, an assessment score, connected to a
                badgering parent. But in reality he is as valuable as the person
                reading this. Children like our son are individuals, with varied
                skill sets and interests. Our son, Henry, loves science and has
                an exceptional full-scale IQ. It seems tragic that he should be
                prevented from contributing to society as fully as he could
                because of substantial service gaps. As it stands, throughout
                his life, we have had to battle against funding deficiencies for
                public services, limited slots for available opportunities, and
                sitting on waiting lists for literally years to be able to earn
                a chance at specialized supports. On top of this, our son will
                age out of services, yet he will never grow out of his needs.
                When public streams dry up, family support and private
                alternatives take up the mantle, or young people are left to
                founder. Infinity Tomorrow seeks to fill that gap, stepping in
                to provide mentoring and placement services for young adults
                living with autism. Thanks to the innovation of Infinity
                Tomorrow, we hope that Henry will have support as he grows to be
                able to find an appropriate match for his skills and interests,
                newfound independence and pride in his accomplishments. How
                Henry’s story unfolds is an open question, and I hope that
                supporting Infinity Tomorrow will, in kind, support a positive
                next step for my son and others like him. Looking forward to
                working with all of you!&rdquo;
              </blockquote>

              <i className="text-align-right"> Heather Fuentes, Esq. </i>
            </Card.Body>
          </Card>
          {/* </div> */}
        </div>
      </section>
      <hr className="m-0" />

      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="contact"
              >
        <div className="w-100">
          <h2>Contact Form and Feedback</h2>
          <div className="pt-6">
      <ContactForm/>
    </div>
        {/* <ButtonGroup className="d-flex ">
            {config.featuredLinks.map(social => {
              const { icon, url, name } = social;
              return (
                <Button
                  variant="success"
                  size="lg"
                  className="w-100 flex-nowrap mx-auto mb-2"
                  key={url}
                  href={url}
                >
                  <i className={`fab ${icon}`}></i>
                  <br />
                  {name}
                </Button>
              );
            })}
          </ButtonGroup>*/}

          {/* <br /> */}
          <hr />
         
      <section className="year-section p-3 p-lg-5 d-flex align-items-center text-center my-4" id="turnover-years">
        <div className="w-100">
          <Image src="../img/Infinitytoday.png" alt="5yr Celebration" fluid/>
          
        </div>
      </section>

          {config.address}
          <br />
          {config.address2}
          <br />
          <br />

          <iframe
            title="Google Map of our Office"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3014.9046918691633!2d-74.08566908458877!3d40.91783557930999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c2fbae050eb56f%3A0x2221f036ff2586ec!2s350%20W%20Passaic%20St%2C%20Rochelle%20Park%2C%20NJ%2007662!5e0!3m2!1sen!2sus!4v1625769983121!5m2!1sen!2sus"
            width="100%"
            height="480px"
            // style="border:0;"
            allowfullscreen=""
            loading="lazy"
          ></iframe>
        </div>
      </section>

      <hr className="m-0" />
    </div>
  </Layout>
);

export default IndexPage;
